<template>
  <div class="summar-line" :style="{height: `${lineHeight}px`}">
    <!-- <img src="../../assets/icon/totalback.gif" /> -->
    <span class="totallabel">{{$t('onlineMonitor.all')}}</span>
    <span class="summar-count">{{totalCount}}</span>
    <img src="../../assets/icon/在线.gif" />
    <span class="summar-count">{{onlineCount}}</span>
    <img v-if="alarmCount > 0" @click="enterAlarm" src="../../assets/icon/Alarm1.gif" style="height: 14px;width:14px;cursor:pointer;"/>
    <img v-else src="../../assets/icon/正常.gif" style="height: 14px;width:14px;"/>
    <img v-if="playSound" @click="playSound = !playSound" src="../../assets/icon/sound_on.gif" style="cursor:pointer;"/>
    <img v-else @click="playSound = !playSound" src="../../assets/icon/sound_off.png" style="cursor:pointer;"/>
    <span class="summar-count">{{alarmCount}}</span>
    <audio :src="`${DOWNLOAD_URL}static/file/Alarm.wav`" loop ref="alarmSound"></audio>
  </div>
</template>

<script>
import {DOWNLOAD_URL} from '@/api/config'
import {setPlaySound, getPlaySound} from '@/common/cache'
export default {
  props: {
    alarmCount: {
      type: Number,
      default: 0
    },
    totalCount: {
      type: Number,
      default: 0
    },
    onlineCount: {
      type: Number,
      default: 0
    },
    lineHeight: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      DOWNLOAD_URL,
      playSound: false
    }
  },
  computed: {
    imgHeight() {
      return this.lineHeight * 0.7
    }
  },
  created() {
    this.playSound = getPlaySound(false)
  },
  methods: {
    enterAlarm() {
      this.$emit('enter-alarm')
      this.$root.$emit('pop-alarm:show')
    }
  },
  watch: {
    alarmCount(val) {
      if (val > 0 && this.playSound) {
        // 播放报警
        this.$refs.alarmSound.play()
      } else {
        // 停止报警
        this.$refs.alarmSound.pause()
      }
    },
    playSound(val) {
      setPlaySound(val)
      if (val && this.alarmCount > 0) {
        // 播放报警
        this.$refs.alarmSound.play()
      } else {
        // 停止报警
        this.$refs.alarmSound.pause()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summar-line {
  padding: 0 8px;
  display: flex;
  align-items: center;
  background-color: #F0F0F0;
  img {
    margin-right: 4px;
  }

  .summar-count {
    font-size: 12px;
    width: 5em;
    overflow: visible;
  }

  .totallabel {
    font-size: 12px;
    padding: 2px;
    border-radius: 2px;
    font-weight: 300;
    background-image: linear-gradient(180deg, #AABCDD 0%, #141C60 75%, #BAD0EA 100%);
    margin-right: 4px;
    color: #FFF;
  }
}
</style>
