<template>
  <div class="deviceTree">
    <transition name="slide-fade">
      <div class="center" v-show="drawer">
        <div class="title">
          <span :class="OTypeID===item.value?'item active':'item'" @click="bindTypes(item.value)"
            v-for="(item,index) in deviceGroup" :key="index">{{$t('commonListVehicle')[item.label]}}</span>
        </div>
        <div class="searchBar">
          <div class="enter">
            <SearchDevice @select="handleSelect" @bindKey="handlerKey" :searchName="searchName"></SearchDevice>
            <div class="cache" v-if="!searchKey">
              <el-popover placement="bottom" :open-delay="0" :boundaries-padding="0" trigger="click">
                <div slot="reference" class="icon-action icon-lishi abox">
                  <i-icon name="iconlishijilu" class="icon-lishi" style="display:block"></i-icon>
                </div>
                <ul class="history-group">
                  <div class="tit">
                    <h4>搜索记录</h4><strong @click="delAllViewsDevice">
                      <i-icon name="iconshanchu"></i-icon>
                    </strong>
                  </div>
                  <el-scrollbar wrapClass="history-scroll">
                    <transition-group name="slidegroup">
                      <div key="1">
                        <li v-for="(tag,index) in deviceViews" :key="index" @click="handlerSearchKey(tag)">
                          <div class="c"><span>{{tag.VehicleName}}{{$t('dict')[tag.TrackerTypeName]}}</span><strong><i
                                class="el-icon-close" @click.stop="closeSelectedTag(tag)"></i></strong></div>
                        </li>
                      </div>
                    </transition-group>
                    <div class="noData" v-if="deviceViews.length===0">
                      <i-icon name="iconwubiaoqing"></i-icon>
                      {{$t('regionManage.nodata')}} !
                    </div>
                  </el-scrollbar>
                </ul>
              </el-popover>
            </div>

          </div>
          <div class="ico" @click="handlerSearch">
            <el-button type="primary" :loading="isSearch" icon="el-icon-search"></el-button>
          </div>
        </div>
        <div class="tabList">
          <div class="tabs" :class="$i18n.locale">
            <div :class="typeID===item.value?'item active':'item'" @click="bindStatus(item.value)"
              v-for="item in statusGroup" :key="item.value">{{$t('commonListVehicle')[item.label]}}
            </div>
          </div>
          <div class="pane">
            <el-scrollbar ref="elScroll" style="height:100%;" v-loading="tableLoading">
              <div class="boxes" v-for="(item,index) in list" :key="index" @click="handleClick(item)">
                <div :class="cachedDevices.includes(item.ObjectID)?'card active':'card'">
                  <div class="info">
                    <div :class="cachedDevices.includes(item.ObjectID)?'check ischeck':'check'">
                      <i-icon name="iconxuanzhong" v-if="cachedDevices.includes(item.ObjectID)"></i-icon>
                      <i></i>
                    </div>
                    <div class="img">
                      <div :class="`icon color${item.classTag}`">
                        <i-icon :name="item.TypeID===0?'iconche-hui':(item.TypeID===1?'iconren-hui':'iconwu-hui')">
                        </i-icon>
                      </div>
                    </div>
                    <div class="view">
                      <h4><strong
                          :title="`${item.VehicleName}-${$t('dict')[item.TrackerTypeName]}`">{{item.VehicleName}}
                        </strong>
                        <!-- <template v-if="item.classTag===4">
                          <span :class="`color${item.classTag}`">{{item.desc}}</span>
                        </template>
                        <template v-else>
                          <span :class="`color${item.classTag}`">{{$t('onlineMonitor')['statusTip'+item.classTag]}}</span>
                        </template> -->

                        <span v-if="typeID===4 &&item.classTag ===4"
                          :class="`color${item.classTag}`">{{$t('onlineMonitor.statusTip7')}}</span>
                        <!-- <span v-else-if="!item.RcvTime &&item.classTag ===4"
                          :class="`color${item.classTag}`">{{$t('onlineMonitor.statusTip7')}}</span> -->
                        <span :class="`color${item.classTag}`"
                          v-else>{{$t('onlineMonitor')['statusTip'+item.classTag]}}</span>
                      </h4>
                      <div class="rows">
                        <div class="tag"><span>
                            <i-icon name="iconlicheng"></i-icon>
                          </span><strong v-if="$i18n.locale==='zh'">{{item.DayMile}}KM</strong> <strong
                            v-else>{{item.Mileage}}KM</strong></div>
                        <div class="tag" v-if="!item.TrackerType">
                          <span>
                            <i-icon name="iconsudu"></i-icon>
                          </span>
                          <strong>{{item.Speed}}KM/H</strong></div>
                        <div class="tag" v-else>
                          <span>
                            <i-icon name="icondianchi"></i-icon>
                          </span>
                          <strong>{{item.Battery}}%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="follows" @click.stop="hanlderFollow(item.ObjectID,item.VehicleID,item.TypeID)">
                    <span v-if="taskList.includes(item.ObjectID)">
                      <i-icon name="iconguanzhu"></i-icon>
                    </span>
                    <span else>
                      <i-icon name="iconweiguanzhu"></i-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div class="noData" v-if="list.length===0">
                <i-icon name="iconwubiaoqing"></i-icon>
                {{$t('regionManage.nodata')}} !
              </div>
            </el-scrollbar>
          </div>
          <div class="pagination">
            <el-pagination @current-change="handleCurrentChange" small layout="pager, total" :total="total"
              :pager-count="5">
            </el-pagination>

          </div>
        </div>
      </div>
    </transition>
    <div class="collapse" @click="()=>{ drawer = !drawer}">
      <div :class="drawer?'on':''">
        <div class="i top"></div>
        <div class="cn">
          <span :class="$i18n.locale">{{$t('commonListVehicle.deviceList')}}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="i bot"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as API from '@/api/common'
  import {
    debounce,
    translateGps
  } from '@/common/utils'
  import {
    mapGetters,
    mapMutations
  } from 'vuex'
  // import miment from 'miment'
  // import {
  //   CancelAlarm
  // } from '@/common/other'
  import {
    setDeviceCache,
    getDeviceCache
  } from '@/common/cache'
  import SearchDevice from '@/views/common/search-device'
  export default {
    name: "home",
    props: {
      user: Number,
      unbindDevice: Object,
      keys: {
        type: Array,
        default: () => ([])
      },
      showArrow: {
        type: Boolean,
        default: false
      },
      cacheOnline: {
        type: Boolean,
        default: true
      },
      arrowDownDefault: {
        type: Boolean,
        default: true
      },
      showDvr: {
        type: Boolean,
        default: false
      },
      resetCheck: {
        type: [String, Object],
        default: null
      },
      upgrade: {
        type: Number,
        default: 15
      },
      updateDevice: {
        type: Array,
        default: () => ([])
      },
    },
    components: {
      SearchDevice
    },
    watch: {
      keys() {
        this.filters[0].Value = ''
        this.debounce_fetchData()
      },
      isDvr(newVal) {
        if (newVal) {
          this.isShowCircuit = newVal
        }
      },
      user: {
        deep: true,
        handler(newVal) {
          if (newVal) {
            let holdIDs = []
            holdIDs.push(newVal)
            this.holdIDs = holdIDs
            this.pageIndex = 1
            this.fetchData()
          }
        }
      },
      unbindDevice: {
        deep: true,
        handler(newVal) {
          if (newVal.isAll) {
            this.checkList = []
          }
        }
      },
      upgrade(n) {
        if (n == 1) {
          this.fetchRefresh()
        }
      },
      updateDevice: {
        deep: true,
        handler(n) {
          if (n) {
            let res = n
            let list = this.list
            if (list.length) {
              list.forEach(item => {
                res.forEach(k => {
                  if (item.ObjectID === k.ObjectID) {
                    item.classTag = translateGps(k.TypeID, k, false)
                    if (k.isOnline && k.isAlarm) {
                      item.class = "color_yellow"
                    } else if (k.isOnline) {
                      item.class = "color_green"
                    } else if (k.Status) {
                      item.class = "color_red"
                    } else {
                      item.class = "color_gray"
                    }
                    // item = Object.assign(item, k)
                  }
                })
              })
              this.list = Object.assign([], this.list)
            }
          }
        }
      }
    },
    computed: {
      rate() {
        return this.pageSize / this.clientSize
      },
      trueIndex() {
        return this.clientIndex % this.rate || this.rate
      },
      clientList() {
        let start = (this.trueIndex - 1) * this.clientSize
        let totalSlice = this.clientSize * this.trueIndex
        let partList = this.list
        return partList
      },
      deviceCache() {
        return getDeviceCache()
      },
      clientList2() {
        let start = (this.trueIndex - 1) * this.clientSize
        let totalSlice = this.clientSize * this.trueIndex
        const partList = this.list.slice(start, totalSlice)
        partList.forEach(k => {
          k.list.forEach(i => {
            i.VehicleName = i.SIM2
            // if(i.ObjectType&& i.ObjectType==3169){
            //   i.disabled = false
            // }else{
            //   i.disabled = true
            // }
            let child = []
            i.list = []
            child = i.videoCircuit.split(",").filter(item => item)
            child.forEach(j => {
              let obj = {
                VehicleName: '摄像头' + j,
                path: j,
                Object: i.ObjectID,
                SIM2: i.SIM2,
                ObjectName: i.ObjectName,
                Vehicle: i.VehicleID,
                ObjectID: parseInt(j) + i.ObjectID
              }
              i.list.push(obj)
            })
          })
        })
        return partList
      },
      ...mapGetters([
        'loginInfo',
        'currentHoldId',
        'taskList',
        'cachedDevices',
        'deviceViews'
      ])
    },
    data() {
      return {
        radio: '',
        OTypeID: null,
        deviceGroup: [{
            label: 'all',
            value: null
          },
          {
            label: 'typeVehicle',
            value: 0
          },
          {
            label: 'typePerson',
            value: 1
          },
          {
            label: 'typeAirtcle',
            value: 2
          }
        ],
        statusGroup: [{
            label: 'all',
            value: 0
          },
          {
            label: 'online',
            value: 1
          },
          {
            label: 'offline',
            value: 2
          },
          {
            label: 'onAlarm',
            value: 3
          },
          {
            label: 'unused',
            value: 4
          },
          {
            label: 'star',
            value: 5
          }
        ],
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        sortKey: '',
        sortDirect: 'ASC',
        searchKey: '',
        filters: [{
            PropertyName: "O.SIM2",
            Value: "",
            Operation: 5,
            Id: null,
            label: "设备ID"
          },
          {
            PropertyName: "O.VehicleNum",
            Value: "",
            Operation: 5,
            Id: null,
            label: "名称"
          }
        ],
        holdIDs: [1],
        typeID: 0,
        isShowCircuit: false,
        list: [], // 设备列表数据
        tableLoading: false,
        // check: '',
        checkList: [],
        followList: [],
        drawer: true,
        isSearch: false,
        searchName: ''
      };
    },
    methods: {
      ...mapMutations({
        'delTaskCar': 'DEL_TASK_CAR',
        'addTaskCar': 'ADD_TASK_CAR',
        'addTaskDevice': 'ADD_TASK_DEVICES',
        'delTaskDevice': 'DEL_TASK_DEVICES',
        'addViewsDevice': 'ADD_VIEWS_DEVICES',
        'delViewsDevice': 'DEL_VIEWS_DEVICES',
        'delAllViewsDevice': 'DEL_ALL_VIEWS_DEVICES'
      }),
      bindTypes(e) {
        this.OTypeID = e
        this.pageIndex = 1
        this.typeID = 0
        this.fetchData()
      },
      bindStatus(e) {
        this.typeID = e
        this.pageIndex = 1
        this.fetchData()
      },
      handlerKey(e) {
        this.searchKey = e
      },
      handlerSearch() {
        this.pageIndex = 1
        this.fetchData()
      },
      hanlderFollow(ObjectID, VehicleID, TypeID) {
        let vm = this
        if (vm.taskList.includes(ObjectID)) {
          let index = vm.taskList.indexOf(ObjectID);
          if (index > -1) {
            API.DeleteStar(VehicleID, TypeID).then(() => {
              this.$message({
                type: 'success',
                message: this.$t('commonListVehicle.followStatus2')
              })
            })
            // vm.taskList.splice(index, 1)
            this.delTaskCar(ObjectID)
          }
        } else {
          // vm.taskList.push(ObjectID)
          API.AddStar(VehicleID, TypeID).then(() => {
            this.$message({
              type: 'success',
              message: this.$t('commonListVehicle.followStatus')
            })
          })
          this.addTaskCar(ObjectID)
        }
      },
      handleSelect(obj) {
        let vm = this
        vm.searchKey = obj.subgroup
        vm.pageIndex = 1
        vm.fetchData()
      },
      handleClick(item) {
        let vm = this
        if (vm.cachedDevices.includes(item.ObjectID)) {
          vm.delTaskDevice(item.ObjectID)
          vm.$emit('click-device', item, false)
        } else {
          // vm.cachedDevices.push(item.ObjectID)
          if (this.cacheOnline) { // 引用组件不保存设备ID
            vm.addTaskDevice(item.ObjectID)
          }
          vm.$emit('click-device', item, true)
          let obj = {
            VehicleName: item.VehicleName,
            ObjectID: item.ObjectID,
            TrackerTypeName: item.TrackerTypeName
          }
          vm.addViewsDevice(obj)
        }
      },
      handlerSearchKey(item) {
        this.typeID = 0
        this.searchKey = item.VehicleName
        this.searchName = item.VehicleName
        this.pageIndex = 1
        this.fetchData()
      },
      handlerDelAll() {

      },
      closeSelectedTag(row) {
        this.delViewsDevice(row)
      },
      handleCurrentChange(val) {
        this.pageIndex = val
        this.fetchData()
      },
      linkTrack(objectId) {
        let vm = this
        vm.$router.push({
          name: 'track',
          query: {
            id: objectId
          }
        })
      },
      fetchRefresh() {
        this.tableLoading = true
        let arr = []
        this.list.forEach(k => {
          let ids = k.ObjectID ? k.ObjectID : 0
          arr.push(ids)
        })
        API.QueryComprehenListByObjectIDs(arr).then(ret => {
          this.loading = false
          this.tableLoading = false
          // this.pageTotal = ret.data.total
          let list = ret.data.list
          list.map(k => {
            k.classTag = translateGps(k.TypeID, k, true)
            if (k.isOnline && k.isAlarm) {
              k.class = "color_yellow"
            } else if (k.isOnline) {
              k.class = "color_green"
            } else if (k.Status) {
              k.class = "color_red"
            } else {
              k.class = "color_gray"
            }
            k.DayMile = k.DayMile.toFixed(1)
            k.Speed = Math.round(k.Speed)
          })
          this.list = Object.assign(this.list, list);
        })
      },
      fetchData() {
        this.tableLoading = true
        let keys = this.keys
        // 如果为车辆登录
        if (!this.loginInfo.isUser) {
          keys = [this.loginInfo.HoldID]
        }
        this.tableLoading = true
        this.filters.forEach(k => {
          k.Value = this.searchKey
        })
        this.isSearch = true
        API.QueryComprehenList(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, this.filters, keys,
          this.typeID, this.showDvr, this.OTypeID).then(res => {
          this.list = res.data.list
          this.total = res.data.total
          this.tableLoading = false
          this.list.map(k => {
            k.classTag = translateGps(k.TypeID, k, true)
            if (k.isOnline && k.isAlarm) {
              k.class = "color_yellow"
            } else if (k.isOnline) {
              k.class = "color_green"
            } else if (k.Status) {
              k.class = "color_red"
            } else {
              k.class = "color_gray"
            }
            k.DayMile = k.DayMile.toFixed(1)
            k.Speed = Math.round(k.Speed)
            let arr = []
            if (k.isFocus) {
              arr.push(k.ObjectID)
            }
            // this.followList = Object.assign(this.followList,arr)
          })
          this.isSearch = false
        })
      }
    },
    mounted() {
      this.searchKey = ''
      this.debounce_fetchData = debounce(this.fetchData, 200)
      this.fetchData()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .searchBar {
    padding: 0 10px 9px 10px;
    display: flex;

    .enter {
      height: 32px;
      // border: 1px solid #40a9ff;
      // border-radius: 20px;
      box-sizing: border-box;
      flex-grow: 1;
      display: flex;
      position: relative;
      z-index: 1;

      .cache {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        .icon-action {
          padding-top: 8px;
          height: 30px;

          .icon {
            margin: 0 auto;
          }
        }
      }
    }

    .ico {
      width: 42px;
      height: 32px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      box-sizing: border-box;
      // background-color: #40a9ff;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      overflow: hidden;

      & ::v-deep .el-button {
        width: 100%;
        height: 100%;
        padding: 5px 0px;
        border-radius: 0;
      }
    }

    // & ::v-deep .el-input__inner {
    //   border-top-left-radius: 20px;
    //   border-bottom-left-radius: 20px;
    // }

    // & ::v-deep .el-input-group__append {
    //   border-top-right-radius: 20px;
    //   border-bottom-right-radius: 20px;
    // }
  }

  .deviceTree {
    // width: 300px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #dcdcdc;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    .center {
      width: 300px;
      height: 100%;
      overflow: hidden;
    }

    .title {
      // background-color: #f2f9ff;
      color: #707070;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      overflow: hidden;

      span {
        padding-left: 10px;
      }

      .active {
        color: #1890ff
      }
    }

    .tabList {
      width: 100%;
      overflow: hidden;
      height: calc(100% - 82px);

      .tabs {
        padding: 0 12px;
        height: 30px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .item {
          font-size: 14px;
          line-height: 24px;
          cursor: pointer;
        }

        .active {
          color: #1890ff
        }
      }

      .fr {
        .item {
          font-size: 13px;
        }
      }

      .pane {

        height: calc(100% - 70px);

        & ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }

        .boxes {
          overflow: hidden;
          padding-right: 10px;
          padding-bottom: 10px;

        }

        .card {
          cursor: pointer;
          height: auto;
          overflow: hidden;
          margin-left: 10px;
          box-sizing: border-box;
          border: 1px solid #e0e1e1;
          border-radius: 10px;
          position: relative;
          z-index: 1;

          .info {
            min-height: 54px;
            padding: 10px 0;
            // border-bottom: 1px solid #e0e1e1;
            box-sizing: border-box;
            display: flex;
            margin-left: 10px;

            .check {
              display: none;
              width: 42px;
              height: 42px;
              text-align: center;
              font-size: 42px;
              overflow: hidden;
              color: #2b3d55;

              i {
                display: block;
                width: 14px;
                height: 14px;
                border: 1px solid #e0e1e1;
                border-radius: 50%;
                margin: 14px auto;
              }
            }

            .ischeck {
              color: #1890ff;
            }

            .img {
              width: 42px;

              .icon {
                width: 40px;
                height: 40px;
                // border: 1px solid #65c240;
                // border-radius: 50%;
              }


            }

            .view {
              flex-flow: 1;
              padding-left: 5px;
              width: 210px;

              h4 {
                font-size: 12px;
                color: #2b3d55;
                font-weight: bold;
                width: 100%;
                height: 24px;
                position: relative;
                z-index: 1;

                strong {
                  display: block;
                  width: 148px;
                  line-height: 18px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                span {
                  display: block;
                  font-size: 12px;
                  text-align: right;
                  position: absolute;
                  right: 0px;
                  top: 2px;
                  z-index: 1;
                }
              }

              .rows {
                display: flex;

                .tag {
                  display: flex;
                  height: 20px;
                  font-size: 12px;
                  color: #666666;
                  padding-right: 20px;

                  span {
                    font-size: 24px;
                    margin-top: -5px;
                  }

                  strong {
                    line-height: 20px;
                  }
                }
              }
            }
          }

          .group {
            display: flex;
            // justify-content: space-between;
            height: 35px;
            display: none;

            .col {
              width: 25%;
              line-height: 35px;
              text-align: center;
              color: #1890ff;
              font-size: 24px;
              border-right: 1px solid #e0e1e1;
              box-sizing: border-box;
            }

            .col:last-child {
              border: none;
            }
          }

          .follows {
            width: 30px;
            height: 30px;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;

            span {
              font-size: 64px;
              position: absolute;
              top: -24px;
              right: -20px;
              color: #e0e1e1;
              z-index: 1;
            }

            .isFollow {
              color: #f1a501;
            }
          }
        }

        .card:hover {
          background-color: #f2f9ff;

          .card {
            border: 1px solid #eef7ff;

            .info {
              border: 1px solid #eef7ff;
            }
          }
        }

        .active {
          background-color: #f2f9ff;
          border-color: #1890ff;

          .card {
            background-color: #f2f9ff;

            .info {
              border: 1px solid #eef7ff;
            }
          }
        }
      }

      .pagination {
        width: 100%;
        height: 30px;
        text-align: center;
        padding-top: 10px;
      }

    }


    .collapse {
      width: 20px;
      position: absolute;
      right: -20px;
      top: 60%;
      margin-top: -80px;
      z-index: 1;
      cursor: pointer;

      .i {
        width: 20px;
        height: 26px;
      }

      .top {
        background: url(../../assets/images/collapse1.png);
        background-size: 100% 100%;
      }

      .bot {
        background: url(../../assets/images/collapse2.png);
        background-size: 100% 100%;
      }

      .cn {
        width: 20px;
        height: auto;
        overflow: hidden;
        background-color: #1890ff;

        span {
          display: none;
          overflow: hidden;
          color: #fff;
          font-size: 12px;
          padding-left: 3px;
          padding-top: 4px;
        }

        .zh {
          display: block;
        }

        i {
          color: #fff;
          transition: transform 0.5s;
        }
      }

      .on {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  .devicePop {
    padding: 6px 0;

    ul {
      width: 100%;

      li {
        padding-left: 15px;
        height: 26px;
        line-height: 26px;
        color: #666666;
        cursor: pointer;
      }

      li:hover {
        color: #1890ff;
        background-color: #f2f9ff;
      }
    }

  }

  .color_green {
    color: #65c240
  }

  .color_gray {
    color: #7f7f7f;
  }

  .color_red {
    color: #ff4141;
  }

  .color_yellow {
    color: #ffdb12;
  }

  .color1 {
    color: #65c240;
  }

  .color2 {
    color: #1469b8;
  }

  .color3 {
    color: #ff4141;
  }

  .color4 {
    color: #7f7f7f;
  }

  .color5 {
    color: #ffd800;
  }

  .color6 {
    color: #7f03d8;
  }


  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active 用于 2.1.8 以下版本 */
    {
    opacity: 0;
  }

  .noData {
    text-align: center;
    padding-top: 20px;

  }

  .history-group {
    & ::v-deep .history-scroll {
      max-height: 260px;
    }

    .tit {
      padding: 0 10px;
      display: flex;
      justify-content: space-between;

      h4 {
        line-height: 30px;
        color: #666666;
      }

      strong {
        line-height: 30px;
        cursor: pointer;
      }

      strong:hover {
        color: #1890ff;
      }
    }

    li {
      margin-bottom: 10px;
      cursor: pointer;
      width: 180px;

      .c {
        border: 1px solid #dcdfe6;
        border-radius: 12px;
        color: #1890ff;
        display: flex;

        span {
          padding-left: 10px;
          height: 24px;
          line-height: 24px;
          width: 85%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        strong {
          width: 27px;
          text-align: center;
          line-height: 24px;
        }
      }
    }

    li:hover {
      .c {
        background-color: #1890ff;
        color: #fff;
      }
    }
  }
</style>