<template>
  <div style="height: 100%;width:100%;">
    <tree-user v-if="simpleMode && show" class="treeUser" :checkbox="false" show-vehicle
      :show-object-count.sync="showObjectCount" show-exchange custom-search fixed-search :drawerDisable="true"
      @exchange="simpleMode=!simpleMode" @click="simpleTreeClick" @click-batch="clickBatch" @click-device="clickDevice"
      :title="$t('commonTreeUser.userList')">
    </tree-user>
    <div class="panes" v-else>
      <div :class="showUserTree?'i-side-pane left':'i-side-pane hidden left'">
        <tree-user ref="treeUser" v-if="show" custom-search :checkbox="false" @return="returnHandler" show-exchange
          show-arrow fixed-search :show-object-count.sync="showObjectCount" :show-return="showReturn" :drawerDisable="true"
          @click="clickHandler" @click-batch="clickBatch" @click-device="clickDevice" @exchange="simpleMode=!simpleMode" @drawer="clickDrawer"
          @expand="userExpand" :title="$t('commonTreeUser.userList')">
          </tree-user>
      </div>
      <div class="i-side-pane right">
        <template v-if="show">
          <list-vehicle ref="list" v-if="!showDvr" :keys="checkHoldKeys" @click-device="clickDevice"
            @click-refresh='clickRefresh' :show-arrow="true" :upgrade='upgrade' :updateDevice="updateDevice" :resetCheck="resetCheck"
            @expand="listExpand" @modify="modify" @modify-device="modifyDevice" @modify-user="modifyUser"
            @sendmsg="sim => $emit('sendmsg', sim)" @command="command" @click-batch="clickBatch" :cacheOnline="cache"
            @transfer-batch="transferBatch">
          </list-vehicle>
          <list-device ref="list" v-if="showDvr" :keys="checkHoldKeys" @click-device="clickDevice"
            @click-refresh='clickRefresh' :show-arrow="true" :upgrade='upgrade' :updateDevice="updateDevice" :resetCheck="resetCheck"
            @expand="listExpand" @modify="modify" @modify-device="modifyDevice" @modify-user="modifyUser"
            @sendmsg="sim => $emit('sendmsg', sim)" @command="command" @click-batch="clickBatch"
            @transfer-batch="transferBatch">
          </list-device>

        </template>
      </div>
    </div>
    <!-- <summar-line v-if="showCount" :alarm-count="summar.alarmCount" :total-count="summar.totalCount"
      :online-count="summar.onlineCount" @enter-alarm="enterAlarm"></summar-line> -->
  </div>
</template>

<script>
  import SplitPane from '@/components/split-pane'
  import TreeUser from './tree-user'
  import ListVehicle from './list-vehicle'
  import ListDevice from './list-device'
  import ListTest from './list-test'
  import {
    mapGetters
  } from 'vuex'
  import SummarLine from '@/views/common/summar-line'
  import {
    QueryVehicleSummarCount,
    GetObjectIdAndVehicleIDByMixID
  } from '@/api/common'
  import {
    setUserTreeSetting,
    getUserTreeSetting
  } from '@/common/cache'

  // 显示列表车辆的混合组件
  export default {
    props: {
      showReturn: {
        type: Boolean,
        default: false
      },
      cache:{
        type: Boolean,
        default: true
      },
      showArrow: {
        type: Boolean,
        default: false
      },
      showCount: {
        type: Boolean,
        default: false
      },
      upgrade: {
        type: Number,
        default: 15
      },
      resetCheck: {
        type: [String, Object],
        default: null
      },
      showDvr: {
        type: Boolean,
        default: false
      },
      updateDevice:{
         type: Array,
        default:() => ([])
      }
    },
    computed: {
      ...mapGetters([
        'loginInfo'
      ])
    },
    components: {
      ListVehicle,
      SplitPane,
      SummarLine,
      TreeUser,
      ListTest,
      ListDevice
    },
    data() {
      return {
        show: true,
        checkHoldKeys: [],
        percent: 38,
        userArrowDown: true,
        listArrowDown: true,
        simpleMode: false,
        summar: {
          totalCount: 0,
          onlineCount: 0,
          alarmCount: 0
        },
        showObjectCount: false,
        auto: false,
        showUserTree:true
      }
    },
    methods: {
      clickDrawer(e){
        this.showUserTree = e
      },
      refresh() {
        this.show = false
        setTimeout(() => {
          this.show = true
        }, 50)
      },
      update() {
        this.$refs.treeUser && this.$refs.treeUser.update && this.$refs.treeUser.update()
        this.$refs.list.update()
      },
      checkHandler(keys) {
        this.checkHoldKeys = keys.slice()
      },
      clickHandler(id) {
        this.checkHoldKeys = [id]
      },
      clickDevice(VehicleID, ObjectID,bool) {
        this.$emit('click-device', VehicleID, ObjectID,bool)
      },
      clickRefresh(e) {
        this.auto = e
      },
      clickBatch(list, bool) {
        this.$emit('click-batch', list, bool)
      },
      transferBatch(data, list) {
        this.$emit('transfer-batch', data)
      },
      returnHandler() {
        this.$emit('return')
      },
      userExpand(val) {
        const percent = val ? 50 : 10
        this.runExpand(this.percent, percent)
      },
      listExpand(val) {
        const percent = val ? 50 : 90
        this.runExpand(this.percent, percent)
      },
      runExpand(start, end) {
        let interval = (end - start) / 18
        let i = 0
        let hander = () => {
          this.percent = this.percent + interval
          if (i++ < 17) {
            setTimeout(hander, 5)
          }
        }
        setTimeout(hander, 17)
      },
      modify(VehicleID) {
        this.$emit('modify', VehicleID)
      },
      modifyDevice(ObjectID) {
        this.$emit('modify-device', ObjectID)
      },
      modifyUser(HoldID) {
        this.$emit('modify-user', HoldID)
      },
      command(row) {
        this.$emit('command', row)
      },
      fetchSummar() {
        QueryVehicleSummarCount().then(ret => {
          this.summar.alarmCount = ret.data.alarmCount
          this.summar.totalCount = ret.data.totalCount
          this.summar.onlineCount = ret.data.onlineCount
        })
      },
      enterAlarm() {
        if (this.simpleMode) {
          this.simpleMode = false
          setTimeout(() => {
            this.$refs.list && (this.$refs.list.selectedTypeId = 3)
          }, 50)
        } else {
          this.$refs.list.selectedTypeId = 3
        }

        this.$emit('enter-alarm')
      },
      simpleTreeClick(id) {
        GetObjectIdAndVehicleIDByMixID([id]).then(ret => {
          if (ret.data.length > 1) {
            let list = ret.data.map(k => k.ObjectID)
            if (list.length > 500) {
              this.$message({
                message: this.$t('onlineMonitor.monitorAllTip'),
                type: 'info'
              })
              list = list.slice(0, 500)
            }

            this.$emit('click-batch', list)
          } else {
            this.$emit('click-device', ret.data[0].VehicleID, ret.data[0].ObjectID)
          }
        })
      }
    },
    watch: {
      simpleMode(val) {
        let state = getUserTreeSetting({})
        state.simpleMode = val
        setUserTreeSetting(state)
      },
      showObjectCount(val) {
        let state = getUserTreeSetting({})
        state.showObjectCount = val
        setUserTreeSetting(state)
      },
      // upgrade(n, o) {
      //   if (n == 1) {
      //     // this.fetchSummar()
      //   }
      // }
    },
    created() {
      if (this.showCount) {
        // 获取数量
        this.fetchSummar()
        // clearInterval(window.timer_summar)
        // window.timer_summar = setInterval(this.fetchSummar, 90000) //左下角在线报警统计数刷新时间间隔
      }
      let state = getUserTreeSetting({})
      // this.simpleMode = state.simpleMode || this.simpleMode
      this.simpleMode = false
      this.showObjectCount = state.showObjectCount || this.showObjectCount
      this.checkHoldKeys = [this.loginInfo.HoldID]
    },
    beforeDestroy() {

    }
  }

</script>

<style lang="scss" scoped>
  .treeUser {
    position: relative !important;
    height: calc(100% - 20px) !important;
  }

  .showCount {
    height: calc(100% - 20px) !important;
  }

  .i-side-pane ::v-deep .el-tree-node.is-current>.el-tree-node__content {
    background-color: #353D43 !important;
  }

  .vue-splitter-container {
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 40px);
      top: 40px;
      right: 0;
      z-index: 1;
      position: absolute;
      background-color: #DCDFE6;
    }
  }

  .panes {
    display: flex;
    width:100%;
    height: 100%;
    .left{
      width:240px;
      height: 100%;
    }
    .hidden{
      width:0;
    }
    .right{
      // width:240px;
      height: 100%;
      position: relative;
    }
  }

</style>
